<template>
  <div class="bg-emerald-50">
    <div v-if="page" class="container mx-auto py-16">
      <h1 class="text-3xl font-bold text-center">{{ page.name }}</h1>
    </div>
  </div>

  <div v-html="page.content"></div>

</template>

<script setup>
import {computed, onMounted, watch} from 'vue';
import {usePage} from "../composables/usePage.js";
import {useStore} from "vuex";

const store = useStore();
const {page, fetchPage} = usePage('services');
const language = computed(() => store.getters['language/language']);

onMounted(fetchPage);

watch(language, () => {
  fetchPage();
});

</script>