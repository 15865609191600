<template>
  <Menu :key="menuKey" />
  <div class="mt-24">
    <router-view/>
  </div>
  <Footer />
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Menu from "./components/layout/Menu.vue";
import Footer from "./components/layout/Footer.vue";

// Track route changes to trigger Menu re-render
const route = useRoute();
const menuKey = ref(0);

watch(route, () => {
  menuKey.value += 1; // Increment the key to force re-render
});
</script>