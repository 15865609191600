<template>
  <div class="bg-emerald-50">
    <div v-if="page" class="container mx-auto py-16">
      <h1 class="text-3xl font-bold text-center">{{ page.name }}</h1>
    </div>
  </div>

  <div v-if="page.contentParsed">
    <div v-html="page.contentParsed[0]"></div>
    <TestimonialsSlider />
    <div v-for="(content, index) in page.contentParsed.slice(1)" :key="index" v-html="content"></div>
  </div>
  <div v-else v-html="page.content"></div>

</template>

<script setup>
import {computed, onMounted, watch} from 'vue';
import {useStore} from 'vuex';
import {usePage} from "../composables/usePage.js";
import TestimonialsSlider from "./Testimonials/TestimonialsSlider.vue";

const store = useStore();
const {page, fetchPage} = usePage('about');
const language = computed(() => store.getters['language/language']);

onMounted(fetchPage);

watch(language, () => {
  fetchPage();
});

</script>